import { axiosApi } from '../axios'
import { decamelize } from 'humps'
import { filterNullFields } from '../../utils/index'

// TODO remove
const endpoint = (tableName, params = {}) => {
  return params?.endpoint ? decamelize(params.endpoint) : decamelize(tableName)
}

export default {
  getItems({ tableName, params }) {
    return axiosApi.get(`/${endpoint(tableName, params)}`, { params })
  },
  getItem({ tableName, id, params }) {
    return axiosApi.get(`/${endpoint(tableName, params)}/${id}`, { params })
  },
  createItem({ tableName, params }) {
    return axiosApi.post(`/${endpoint(tableName, params)}`, filterNullFields(params))
  },
  addNewItem(endpoint, params) {
    return axiosApi.post(`/${decamelize(endpoint)}`, filterNullFields(params))
  },
  updateItem({ tableName, params, id }) {
    return axiosApi.put(`/${endpoint(tableName, params)}/${id}`, params)
  },
  deleteItem({ tableName, params, id }) {
    return axiosApi.delete(`/${endpoint(tableName, params)}/${id}`)
  },
  getSimplifiedList(tableName, params) {
    return axiosApi.get(`/${tableName}/simple_index`, { params })
  },
  getFilters() {
    return axiosApi.get('/filters')
  },
  login(params) {
    return axiosApi.post('/sessions', params)
  },
  logout() {
    return axiosApi.delete('/sessions')
  },
  resetPassword(params) {
    return axiosApi.post('/contacts/reset_password', params)
  },
  grantContactAccess(id, email) {
    return axiosApi.post(`/contacts/${id}/grant_access`, { email })
  },
  changeContactPassword(id, email) {
    return axiosApi.post(`/contacts/${id}/change_password`, { email })
  },
  calculatePrice(params) {
    return axiosApi.get('/prices', { params: filterNullFields(params) })
  },
  sendCourse({ params, id }) {
    return axiosApi.post(`/courses/${id}/send_course`, params)
  },
  generateOrderKwu(id, params) {
    return axiosApi.post(`/orders/${id}/generate_kwu`, params)
  },
  generateCourseKwu(id, params) {
    return axiosApi.post(`/courses/${id}/generate_kwu`, params)
  },
  generateInvoiceKwu(id) {
    return axiosApi.post(`/invoices/${id}/generate_kwu`)
  },
  deleteInvoiceKwu(id, params) {
    return axiosApi.delete(`/invoices/${id}/kwu_file`, { params })
  },
  cancelCourse(id) {
    return axiosApi.patch(`/courses/${id}/cancel`)
  },
  startCourse(id) {
    return axiosApi.patch(`/courses/${id}/start`)
  },
  finishCourse({ id, ...params }) {
    return axiosApi.patch(`/courses/${id}/finish`, params)
  },
  renewCourse(id) {
    return axiosApi.patch(`courses/${id}/renew`)
  },
  assignOrder({ id, params }) {
    return axiosApi.patch(`/courses/${id}/assign_container`, params)
  },
  unassignOrder(id) {
    return axiosApi.patch(`/courses/${id}/unassign`)
  },
  resendTextConfirmation(id) {
    return axiosApi.post(`/courses/${id}/resend_text_confirmation`)
  },
  generateDigitalSignature(id) {
    return axiosApi.post(`/courses/${id}/digital_signature`)
  },
  postponeCourses(params) {
    return axiosApi.patch('/courses/postpone', params)
  },
  updateTailCourse({ id, ...params }) {
    return axiosApi.patch(`/courses/${id}/tail`, params)
  },
  archiveInvoices(params) {
    return axiosApi.get('/invoices/archive', {
      params,
      responseType: 'blob'
    })
  },
  getReport(params, tab) {
    return axiosApi.get(`/reports/${tab}`, { params })
  },
  exportReport(params) {
    return axiosApi.get('/reports/export', {
      params,
      responseType: 'blob'
    })
  },
  exportCoursesSpreadsheet(params) {
    return axiosApi.get('courses/export_csv', {
      params,
      responseType: 'blob'
    })
  },
  exportInvoicesSpreadsheet(params) {
    return axiosApi.get('invoices/export_csv', {
      params,
      responseType: 'blob'
    })
  },
  exportOrdersSpreadsheet(params) {
    return axiosApi.get('orders/export_csv', {
      params,
      responseType: 'blob'
    })
  },
  getRoute(id) {
    return axiosApi.get(`/routes/${id}`)
  },
  sendRoute({ params, id }) {
    return axiosApi.patch(`/routes/${id}/send_route`, params)
  },
  getOrder(id, nonSwapView) {
    const params = nonSwapView ? { nonSwapView } : {}
    return axiosApi.get(`/orders/${id}`, { params })
  },
  getSimilarOrdersPrices(params) {
    return axiosApi.get('/orders/nearby_prices', { params })
  },
  uploadOrderFiles(orderId, files) {
    return axiosApi.post(`/orders/${orderId}/files`, files, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteOrderFiles(fileId) {
    return axiosApi.delete(`/order_files/${fileId}`)
  },
  sendFiles({ ids, contactEmails }) {
    return axiosApi.post('/order_files/send_mail', { contactEmails, ids })
  },
  sendInvoices(params) {
    return axiosApi.post('/invoices/send_mail', params)
  },
  getClientFiles(clientId) {
    return axiosApi.get('/client_files', { params: { clientId } })
  },
  uploadClientFile(file) {
    return axiosApi.post('/client_files', file, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteClientFile(fileId) {
    return axiosApi.delete(`/client_files/${fileId}`)
  },
  uploadBDOFile(orderId, file) {
    return axiosApi.post(`/orders/${orderId}/bdo_file`, file, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteBDOFile(orderId, fileId) {
    return axiosApi.delete(`/orders/${orderId}/bdo_file`, { params: { id: fileId } })
  },
  getBdoCard(bdoCardId) {
    return axiosApi.get(`/bdo_cards/${bdoCardId}`)
  },
  getBdoCardsFilters(params) {
    return axiosApi.get('/bdo_cards/filter_items', { params })
  },
  refreshBdoCards(params) {
    return axiosApi.get('/bdo_cards/refresh', { params })
  },
  fetchNewBdoCards(params) {
    return axiosApi.get('/bdo_cards/fetch_new', { params })
  },
  assignCourseToBdoCard(bdoCardId, courseId) {
    return axiosApi.patch(`/bdo_cards/${bdoCardId}/assign_to_course`, { courseId })
  },
  unassignOrderFromBdoCard(bdoCardId) {
    return axiosApi.patch(`/bdo_cards/${bdoCardId}/unassign`)
  },
  confirmBdoCard(bdoCardId, params) {
    return axiosApi.patch(`/bdo_cards/${bdoCardId}/receive_confirmation`, params)
  },
  confirmTransport(bdoCardId) {
    return axiosApi.patch(`/bdo_cards/${bdoCardId}/transport_confirmation`)
  },
  addBdoCardTemplate(params) {
    return axiosApi.post('/bdo_card_templates', params)
  },
  createBdoCardFromTemplate({ id, ...params }) {
    return axiosApi.post(`/bdo_card_templates/${id}/transform`, params)
  },
  massConfirmTransport(ids) {
    return axiosApi.patch('/bdo_cards/mass_transport_confirmation', { ids })
  },
  editScheduleStatus({ params, id }) {
    return axiosApi.patch(`/order_schedules/${id}`, params)
  },
  setClientBlock(id, blockExplanation) {
    return axiosApi.patch(`/clients/${id}/block`, { blockExplanation })
  },
  setClientUnblock(id) {
    return axiosApi.patch(`/clients/${id}/unblock`)
  },
  getClient(id) {
    return axiosApi.get(`/clients/${id}`)
  },
  getClientLastDoneOrder(id, params) {
    return axiosApi.get(`/clients/${id}/last_order`, { params })
  },
  createProduct(params) {
    return axiosApi.post('/products', params)
  },
  editProduct({ id, ...params }) {
    return axiosApi.patch(`/products/${id}`, params)
  },
  getIncident(id) {
    return axiosApi.get(`/incidents/${id}`)
  },
  getInvoice(id) {
    return axiosApi.get(`/invoices/${id}`)
  },
  getOrderSchedule(id) {
    return axiosApi.get(`/order_schedules/${id}`)
  },
  fetchBdoPdfs(bdoCardId) {
    return axiosApi.get(`/bdo_cards/${bdoCardId}/fetch_pdf`)
  },
  searchClients(params) {
    return axiosApi.get('/clients/quick_search', { params })
  },
  fetchGusData(params) {
    return axiosApi.get('/clients/fetch_gus_data', { params })
  },
  searchAddresses(params) {
    return axiosApi.get('/addresses/quick_search', { params })
  },
  checkAddressOrders(id) {
    return axiosApi.get(`/addresses/${id}/check_orders`)
  },
  getDriversOccupancy(params) {
    return axiosApi.get('/drivers/daily_summary', { params })
  },
  fetchCommunes(params) {
    return axiosApi.get('/communes/fetch', { params })
  },
  getDebrisTypes(params) {
    return axiosApi.get('/debris_types', { params })
  },
  fetchDebrisTypes(params) {
    return axiosApi.get('/debris_types/fetch', { params })
  },
  getBussinessPlaces(params) {
    return axiosApi.get('/business_places', { params })
  },
  importBusinessPlaces() {
    return axiosApi.post('/business_places/import')
  },
  rejectBdoCard(bdoCardId, params) {
    return axiosApi.patch(`bdo_cards/${bdoCardId}/reject`, params)
  },
  generateConfirmation(bdoCardId, params) {
    return axiosApi.patch(`bdo_cards/${bdoCardId}/generate_confirmation`, params)
  },
  reviseBdoCard(bdoCardId, params) {
    return axiosApi.patch(`bdo_cards/${bdoCardId}/revise`, params)
  },
  updateTransportTime(bdoCardId, plannedTransportTime) {
    return axiosApi.patch(`bdo_cards/${bdoCardId}/update_via_bdo`, { plannedTransportTime })
  },
  notifyCall(params) {
    // tmp
    return axiosApi.post('/crm/call_notify', params)
  },
  createCallEntry({ callEntryId, ...params }) {
    return axiosApi.patch(`/call_entries/${callEntryId}`, params)
  },
  getSingleProspect(id) {
    return axiosApi.get(`/prospects/${id}`)
  },
  addProspectNote(params) {
    return axiosApi.post('/prospect_notes', params)
  },
  getHints() {
    return axiosApi.get('/hints')
  },
  getContainersCounters(params) {
    return axiosApi.get('/containers/counters', { params })
  },
  getInvoicesCounters(params) {
    return axiosApi.get('/invoices/counters', { params })
  },
  getCoursesCounters(params) {
    return axiosApi.get('/courses/counters', { params })
  },
  getBdoCardsCounters(params) {
    return axiosApi.get('/bdo_cards/counters', { params })
  },
  getOrdersCounters(params) {
    return axiosApi.get('/orders/counters', { params })
  },
  getInvoicePreview(params) {
    return axiosApi.get('/invoices/preview_main', { params })
  },
  correctInvoice({ params, id }) {
    return axiosApi.post(`/invoices/${id}/correction`, params)
  },
  getCorrectionPreview({ params, id }) {
    return axiosApi.get(`/invoices/${id}/preview_correction`, { params })
  },
  createHint(params) {
    return axiosApi.post('/hints', params)
  },
  updateHint(hintId, params) {
    return axiosApi.put(`/hints/${hintId}`, params)
  },
  deleteHint(hintId) {
    return axiosApi.delete(`/hints/${hintId}`)
  },
  getClientAppOrdersSettings() {
    return axiosApi.get('/settings/client_app_orders')
  },
  setClientAppOrdersSettings(params) {
    return axiosApi.put('/settings', params)
  },
  getUserSettings(viewType) {
    return axiosApi.get('/user_settings', { params: { viewType } })
  },
  updateUserSettings(params) {
    return axiosApi.put('/user_settings', params)
  },
  getDefaultDebrisTypeCodes() {
    return axiosApi.get('/settings/default_debris_type_codes')
  },
  setDefaultDebrisTypeCodes(params) {
    return axiosApi.patch('/settings/update_default_debris_type_codes', params)
  },
  sendPaymentLinkByMail(data) {
    const { mode, ...params } = data
    return axiosApi.post(`/payments/pay_by_link/mail/${mode}`, params)
  },
  sendPaymentLinkBySMS(data) {
    const { mode, ...params } = data
    return axiosApi.post(`/payments/pay_by_link/text/${mode}`, params)
  },
  refreshPDF(id, params) {
    return axiosApi.patch(`/invoices/${id}/refresh_pdf`, params)
  },
  updateSetting(params) {
    return axiosApi.patch('/settings', params)
  },
  deleteInvoiceStatus(id) {
    return axiosApi.delete(`/orders/${id}/invoice_status`)
  },
  getAvailableInvoiceNumbers(params) {
    return axiosApi.get('/invoices/available_invoice_numbers', { params })
  },
  editOrderTemplatePixelStatus({ id, pixelStatus }) {
    return axiosApi.patch(`/order_templates/${id}/pixel_status`, { pixelStatus })
  },
  updateCodDeposit(params) {
    return axiosApi.patch(`/cod_deposits/${params.id}`, params)
  },
  addCodDeposit(params) {
    return axiosApi.post('/cod_deposits', params)
  }
}
