<template>
  <v-snackbar
    v-model="show"
    :color="color"
    timeout="5000"
  >
    <div
      v-for="(message, index) in content"
      :key="index"
      class="subtitle-2"
    >
      {{ message }}
    </div>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('snackbar', [
      'isNotified',
      'color',
      'content'
    ]),

    show: {
      get () {
        return this.isNotified
      },
      set () {
        setTimeout(this.clearSnackbar, 500)
      }
    }
  },

  methods: {
    ...mapActions('snackbar', [
      'clearSnackbar'
    ])
  }
}
</script>
