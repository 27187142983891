import actions from '../actions'
const addressName = (address) => address.name || address.formattedAddress

const clientOrders = {
  header: [],
  table: {
    maximumDateRange: 1000,
    disableSavingTableSettings: true,
    specialFlag: {
      done: false,
      endpoint: 'orders'
    },
    modes: [
      { value: { done: false }, initial: true, text: 'Zlecenia otwarte' },
      { value: { done: true }, text: 'Zlecenia zamknięte' },
    ],
    sorting: {
      sortBy: 'refNumber',
      sortDesc: true
    },
    filters: [
      { name: 'done', filterBy: false, hide: true, propPath: 'done' },
      { name: 'clientIds', text: 'Klient', options: 'clients', filterBy: [], propPath: 'clientId', multiple: true },
      { name: 'addressIds', text: 'Lokalizacja', options: 'addresses', filterBy: '', nameGetter: addressName, propPath: 'addressId', multiple: true },
      { name: 'courseTypes', text: 'Typ kursu', options: 'ordersCourseTypes', filterBy: [], checkCourseType: true, multiple: true },
      { name: 'paymentTypes', text: 'Status płatności', options: 'paymentTypes', filterBy: [], propPath: 'paymentType', multiple: true },
      { name: 'settlementTypes', text: 'Typ dokumentu', options: 'settlementTypes', filterBy: [], propPath: 'settlementType', multiple: true },
      { name: 'paymentStatuses', text: 'Typ płatności', options: 'paymentStatuses', filterBy: [], propPath: 'paymentStatus', multiple: true },
      { name: 'departmentId', text: 'Oddział', options: 'departments', filterBy: undefined, propPath: '' },
      { name: 'origin', text: 'Pochodzenie', options: 'orderOrigins', filterBy: '', propPath: 'parsedOrigin' },
    ],
    parameters: [
      { name: 'select', value: 'select', text: '', show: true, sortable: false },
      { name: 'refNumber', text: 'Nr zlecenia', value: 'refNumber', show: true },
      { name: 'address', text: 'Lokalizacja', value: 'addressDisplayName', show: true },
      { name: 'departmentName', text: 'Oddział', value: 'departmentId', show: true },
      { name: 'clientName', text: 'Klient', value: 'clientName', show: true },
      { name: 'leaveDate', text: 'Data podstawienia', value: 'leaveCourseDueDate', show: true, isDateField: true },
      { name: 'pickupDate', text: 'Data zabrania', value: 'pickupCourseDueDate', show: true, isDateField: true },
      { name: 'dueDate', text: 'Data transportu', value: 'otherCourseDueDate', show: true, isDateField: true },
      { name: 'container', text: 'Kontener', value: 'containerWithType', show: true },
      { name: 'paymentType', text: 'Typ płatności', value: 'paymentType', show: true },
      { name: 'settlementType', text: 'Typ dokumentu', value: 'settlementType', show: true },
      { name: 'totalGrossValue', text: 'Do zapłaty', value: 'totalGrossValue', show: true },
      { name: 'paidValue', text: 'Pobrano', value: 'paidValue', show: true },
      { name: 'paymentStatus', text: 'Status płatności', value: 'paymentStatus', show: true },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.generateInvoice, icon: 'wygeneruj_fakture' },
      { ...actions.deleteClientOrder, icon: 'usun' }
    ],
    menu: [
      { ...actions.editOrder },
      { ...actions.deleteClientOrder },
      { ...actions.generateInvoice },
      { ...actions.assignContainer }
    ],
    daterangeCriteria: ['otherCourseDueDate', 'leaveCourseDueDate', 'pickupCourseDueDate']
  },
  details: {},
  webSockets: {
    showChannel: 'OrderShowChannel',
    indexChannel: 'OrderIndexChannel',
    idKey: 'orderId'
  }
}

const clientBDO = {
  header: [],
  table: {
    maximumDateRange: 366,
    specialFlag: {
      endpoint: 'bdoCards'
    },
    sorting: {
      sortBy: 'order.refNumber',
      sortDesc: false
    },
    filters: [
      { name: 'clientId', text: 'Klient', options: 'clients', filterBy: '', hide: true, propPath: 'clientId' },
      { name: 'cardStatuses', text: 'Status karty', options: 'bdoCardStatuses', filterBy: [], propPath: 'paymentStatus', multiple: true }
    ],
    parameters: [
      { name: 'cardNumber', text: 'Nr karty', value: 'cardNumber', show: true, sortable: true },
      { name: 'cardStatus', text: 'Status karty', value: 'cardStatus', show: true, sortable: false },
      { name: 'refNumber', text: 'Nr zlecenia', value: 'orderRefNumber', show: true },
      { name: 'orderType', text: 'Typ zlecenia', value: 'orderType', show: true },
      { name: 'debrisTypeCode', text: 'Kod odpadu', value: 'reportedDebrisTypeCode', show: true },
      { name: 'debrisTypeName', text: 'Typ odpadu', value: 'reportedDebrisTypeDisplayName ', show: true },
      { name: 'senderName', text: 'Przekazujący', value: 'senderName', show: true },
      { name: 'receiverName', text: 'Przyjmujący', value: 'receiverName', show: true },
      { name: 'vehicleRegNumber', text: 'Nr rejestracyjny', value: 'vehicleRegNumber', show: true },
      { name: 'plannedTransportTime', text: 'Planowany czas', value: 'plannedTransportTime', show: true },
      { name: 'realTransportTime', text: 'Rzeczywisty czas', value: 'realTransportTime', show: true }
    ],
    actions: [],
    menu: []
  },
  details: {},
  webSockets: {
    showChannel: 'BdoCardShowChannel',
    indexChannel: 'BdoCardIndexChannel',
    idKey: 'bdoCardId'
  }
}

const clientInvoices = {
  header: [],
  table: {
    maximumDateRange: 1096,
    specialFlag: {
      departmentId: undefined,
      paid: false,
      endpoint: 'invoices'
    },
    modes: [
      { value: { paid: false }, done: true, initial: true, text: 'Dokumenty otwarte' },
      { value: { paid: true }, text: 'Dokumenty zamknięte' },
    ],
    sorting: {
      sortBy: 'issueDate',
      sortDesc: true
    },
    filters: [
      { name: 'paid', filterBy: false, hide: true, propPath: 'paid' },
      { name: 'clientIds', filterBy: [], hide: true, propPath: 'client.id', multiple: true },
      { name: 'settlementTypes', text: 'Typ dokumentu', options: 'settlementTypes', filterBy: '', propPath: 'settlementType', multiple: true },
      { name: 'paymentTypes', text: 'Typ płatności', options: 'paymentTypes', filterBy: [], propPath: 'paymentType', multiple: true },
      { name: 'paymentStatuses', text: 'Status płatności', options: 'paymentStatuses', filterBy: [], propPath: 'paymentStatus', multiple: true }
    ],
    parameters: [
      { name: 'select', show: true, value: 'select', text: '', sortable: false },
      { name: 'issueDate', text: 'Data wystawienia', value: 'issueDate', show: true },
      { name: 'exportDate', text: 'Data eksportu', value: 'exportDate', show: true },
      { name: 'createdAt', text: 'Data utworzenia', value: 'createdAt', show: true },
      { name: 'invoiceNumber', text: 'Nr dokumentu', value: 'invoiceNumber', show: true },
      { name: 'buyerName', text: 'Nabywca', value: 'buyerName', show: true },
      { name: 'nip', text: 'Nr NIP', value: 'nip', show: true },
      { name: 'issueDate', text: 'Data sprzedaży', value: 'issueDate', show: true },
      { name: 'paymentType', text: 'Typ płatności', value: 'paymentType', show: true },
      { name: 'paidValue', text: 'Zapłacono', value: 'paidValue', show: true },
      { name: 'paymentStatus', text: 'Status płatności', value: 'paymentStatus', show: true },
      { name: 'paymentDate', text: 'Data płatności', value: 'paymentDate', show: true },
      { name: 'optimaStatus', text: 'Status Optima', value: 'optimaStatus', show: true, sortable: false },
      { name: 'actions', text: '', value: 'actions', show: true, sortable: false }
    ],
    actions: [
      { ...actions.deleteClientInvoice, icon: 'usun' },
      { ...actions.downloadInvoice, icon: 'pobierz' }
    ],
    menu: [
      { ...actions.deleteClientInvoice },
      { ...actions.downloadInvoice }
    ]
  },
  details: {},
  webSockets: {
    showChannel: 'InvoiceShowChannel',
    indexChannel: 'InvoiceIndexChannel',
    idKey: 'invoiceId'
  }
}

export {
  clientOrders,
  clientBDO,
  clientInvoices
}
