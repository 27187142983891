import api from '../api/v1'

const callEntry = {
  courseIds: [],
  clientId: null,
  callEntryId: null,
  phoneNumber: null,
  callStart: null,
  contactId: null,
}

export default {
  namespaced: true,
  state: {
    isProcessing: false,
    callEntry: Object.assign(callEntry)
  },
  mutations: {
    SET_CALL_ENTRY_PROCESSING (state, status) {
      state.isProcessing = status
    },
    ADD_COURSE_ID (state, id) {
      state.callEntry.courseIds.push(id)
    },
    SETUP_CALL_ENTRY (state, callEntry) {
      state.callEntry = {
        ...state.callEntry,
        ...callEntry
      }
    },
    CLEAR_CALL_ENTRY (state) {
      state.callEntry = Object.assign(callEntry)
    }
  },
  actions: {
    setupCallEntry ({ commit }, { clientId, phoneNumber, callStart, contactId, callEntryId }) {
      commit('SETUP_CALL_ENTRY', { clientId, phoneNumber, callStart, contactId, callEntryId })
    },
    addCourse ({ commit }, courseIds) {
      courseIds.forEach(id => commit('ADD_COURSE_ID', id))
    },
    createCallEntry ({ state, commit, dispatch }, { notes, contactId }) {
      commit('SET_CALL_ENTRY_PROCESSING', true)
      const params = {
        ...state.callEntry,
        contactId: state.callEntry.contactId || contactId || null,
        direction: 'Wychodzące',
        notes
      }
      return api.createCallEntry(params)
        .then(() => {
          commit('CLEAR_CALL_ENTRY')
          dispatch('snackbar/showSnackbar', {
            message: ['Utworzono zapis z rozmowy'],
          }, { root: true })
        })
        .finally(() => {
          commit('SET_CALL_ENTRY_PROCESSING', false)
        })
    },
  }
}
