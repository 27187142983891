<template>
  <div>
    <Header
      ref="header"
      :title="$route.meta.title"
      :table-name="tableName"
    >
      <MultipleItemsActions
        v-for="action in multipleActionButtons"
        :key="action.subject"
        :action="action"
        :table-name="tableName"
      />
    </Header>
    <Tabs
      v-if="configured"
      ref="tabs"
      :tabs="tabs"
      persistent
      @goTo="goTo"
    />
    <Table
      ref="table"
      :table-name="tableName"
      :key="tableName"
      @contextMenu="openContextMenu"
      @openDetails="openSingleBdoCardView"
      @configured="configured = true"
    >
      <template #tableTop>
        <FiltersBar
          ref="tableTop"
          :table-name="tableName"
          search-bar
          :search-bar-props="{class: tableName !== 'bdoCards' && 'mr-4'}"
          :date-range-picker="['bdoCards','internalBdoCards'].includes(tableName)"
          show-filters-button
          table-filters
        />
      </template>
      <template #stats>
        <template v-if="tableName !== 'assigningBdoCards'">
          <div class="ml-10 mr-12 d-flex">
            <span class="table-statistics__label">Łączna masa odpadów</span>
            <span class="table-statistics__value">{{ counters.reportedWeight || 0 }} T</span>
          </div>
          <div class="d-flex">
            <span class="table-statistics__label">Łączna raportowana waga</span>
            <span class="table-statistics__value">{{ counters.orderReportedWeight || 0 }} T</span>
          </div>
        </template>
      </template>
    </Table>
    <TableConfirmationBar
      v-if="selectedItems.length"
      :confirm-actions="confirmationBarActions"
      :table-name="tableName"
      ref="confirmation"
    />
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import Table from '../../components/Table/Table'
import FiltersBar from '../../components/Filters/FiltersBar'
import MultipleItemsActions from '../../components/Elements/MultipleItemsActions'
import TableConfirmationBar from '../../components/Table/TableConfirmationBar'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'
import { mapGetters, mapActions, mapState } from 'vuex'
import { massConfirmTransportShortkeys } from '../../const/shortKeys'
import actions from '../../const/actions'
import tabs from '../../helpers/bdoCardsTabs'

export default {
  components: {
    Header,
    Tabs,
    Table,
    FiltersBar,
    MultipleItemsActions,
    TableConfirmationBar,
  },
  mixins: [defaultTableEventsMixin, webSocketMixin],
  channels: {
    BdoCardIndexChannel: {
      received (data) {
        this.captureChanges(data)
        this.getCounters()
      }
    },
  },
  data: () => ({
    tableName: 'bdoCards',
    configured: false,
    areWatchersActive: false,
    tabs,
  }),
  computed: {
    ...mapGetters({
      getFilterValue: 'tables/getFilterValue'
    }),
    ...mapState({
      counters: state => state.bdoCards.counters,
    }),
    selectedItems() {
      return this.$store.state[this.tableName].selectedItems || []
    },
    multiselectSubject() {
      return this.$store.state[this.tableName].multiselectSubject
    },
    clientFilter () {
      return this.getFilterValue(this.tableName, 'clientId')
    },
    wasteGeneratingFilter () {
      return this.getFilterValue(this.tableName, 'wasteGenerating')
    },

    filtersValues() {
      const { filters } = this.$store.getters['tables/getTableConfig'](this.tableName)
      return filters.map(filter => filter.filterBy)
    },

    cards () {
      return this.$store.state[this.tableName].items
    },
    multipleActionButtons() {
      switch (this.tableName) {
        case 'bdoCards':
          return [
            { label: 'Potwierdź transport', subject: 'massConfirmTransport', shortkey: massConfirmTransportShortkeys, confirmActions: [actions.massConfirmTransport] },
          ]
        default:
          return []
      }
    },
    confirmationBarActions () {
      return this.multipleActionButtons.find(action => action.subject === this.multiselectSubject)?.confirmActions || []
    },
  },
  watch: {
    filtersValues: {
      deep: true,
      handler () {
        if (this.tableName !== 'bdoCardTemplates') {
          this.setBdoCardsOptions()
        }
      }
    },
    clientFilter (clientId) {
      if (this.areWatchersActive) {
        const clearBusinessPlacesFilter = (filterName) => {
          this.setTableFilters({ filters: [{ name: filterName, filterBy: '', disabled: !clientId }], tableName: this.tableName, disableFetch: !clientId })
        }

        clearBusinessPlacesFilter('senderBusinessPlaceId')
      }
    },
    wasteGeneratingFilter (filterValue) {
      if (this.areWatchersActive) {
        this.setTableFilters({
          filters: [{ name: 'communeId', filterBy: '', disabled: !filterValue, }],
          tableName: this.tableName,
          disableFetch: !!filterValue
        })
      }
    },
    selectedItems (newValue, oldValue) {
      if (newValue.length !== oldValue.length && (newValue.length === 0 || oldValue.length === 0)) {
        this.$refs.table.resizeTable()
      }
    },
    tableName() {
      if (this.tableName === 'bdoCardTemplates') {
        this.setBdoCardTemplatesOptions()
      }
    }
  },
  mounted () {
    this.subscribeSocket('BdoCardIndexChannel')
    this.setClientsOptions({})
    this.unsubscribeAction = this.$store.subscribeAction({
      after: action => {
        if (['bdoCards/getItems'].includes(action.type)) {
          this.getCounters()
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribeSocket('BdoCardIndexChannel')
    this.setClientsOptions()
    this.unsubscribeAction()
  },
  methods: {
    ...mapActions({
      setClientsOptions: 'core/setClientsOptions',
      setBdoCardsOptions: 'core/setBdoCardsOptions',
      setBdoCardTemplatesOptions: 'core/setBdoCardTemplatesOptions',
      setTableFilters: 'tables/setTableFilters',
      getCounters: function(dispatch) {
        return dispatch(`${this.tableName}/getCounters`)
      },
    }),

    openSingleBdoCardView (item) {
      if (this.tableName === 'bdoCardTemplates') {
        this.openTableDetails(item, 'singleBdoTemplate')
      } else {
        this.$router.replace({ name: 'singleBdo', params: { id: item.id } })
      }
    },
    goTo (tab) {
      this.areWatchersActive = false
      this.tableName = tab.value
      this.$nextTick(() => {
        this.areWatchersActive = true
      })
    },
  }
}
</script>
