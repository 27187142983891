<template>
  <div>
    <Header
      ref="header"
      v-if="tableName"
      :title="$route.meta.title"
      :table-name="tableName"
    >
      <MultipleItemsActions
        v-for="action in multipleActionButtons"
        :key="action.subject"
        :action="action"
        :table-name="tableName"
      />
    </Header>
    <div class="table-view-wrapper">
      <div>
        <Tabs
          v-if="configured[tableName]"
          ref="tabs"
          :tabs="tabs"
          persistent
          :disabled="isProcessing"
          @goTo="goTo"
        />
        <Table
          v-if="tableName"
          ref="table"
          :key="currentTab"
          :current-tab="currentTab"
          :table-name="tableName"
          :disable-pagination="tableName !== 'coursesWithoutCards'"
          @selectRow="selectTableRow"
          @openDetails="openTableDetails"
          @contextMenu="openContextMenu"
          @configured="setTableConfiguredStatus"
        >
          <template #tableTop>
            <FiltersBar
              ref="tableTop"
              :table-name="tableName"
              search-bar
              date-range-picker
              :date-range-picker-props="{
                hidePresets: sidebar.size > 0,
                clearable: tableName === 'coursesWithoutCards'
              }"
              show-filters-button
              table-filters
            />
          </template>
          <template #stats>
            <TableStatisticsBar
              ref="statistics"
              class="text-capitalize"
              :table-name="tableName"
              drivers-occupancy
            />
          </template>
        </Table>
      </div>
      <TableConfirmationBar
        v-if="isMultiselectActive"
        :confirm-actions="confirmationBarActions"
        :table-name="tableName"
        ref="confirmation"
      />
    </div>
    <Sidebar>
      <component
        v-if="detailsKey"
        :key="detailsKey"
        :is="componentTableDetails"
        :table-name="tableName"
        :data="chosenItem"
        :actions="availableActions"
      />
    </Sidebar>
  </div>
</template>

<script>
import Header from '../../components/Layout/Header'
import Tabs from '../../components/Layout/Tabs'
import Table from '../../components/Table/Table'
import SingleViewOrder from '../../components/Single/SingleViewOrder'
import SinglePreviewRoute from '../../components/Single/SinglePreviewRoute'
import FiltersBar from '../../components/Filters/FiltersBar'
import Sidebar from '../../components/Layout/Sidebar/Sidebar'
import { mapActions, mapState, mapGetters } from 'vuex'
import defaultTableEventsMixin from '../../mixins/defaultTableEventsMixin'
import webSocketMixin from '../../mixins/webSocketMixin'
import MultipleItemsActions from '../../components/Elements/MultipleItemsActions'
import TableStatisticsBar from '../../components/Table/TableStatisticsBar'
import TableConfirmationBar from '../../components/Table/TableConfirmationBar'
import actions from '../../const/actions'
import {
  getLabelWithShortkey,
  multiselectShortkeys,
  exportShortkeys
} from '../../const/shortKeys'
import cloneDeep from 'lodash/cloneDeep'

const tabs = Object.freeze([
  {
    value: 'courses',
    tableName: 'courses',
    text: 'Wszystkie kursy',
    filters: [{ name: 'courseStatuses', filterBy: [], hide: false }, { name: 'clientBdoTypes', hide: false, disabled: false }, { name: 'bdoCardStatuses', hide: false, disabled: false }]
  },
  {
    value: 'coursesToBePlanned',
    tableName: 'courses',
    text: 'Kursy do zaplanowania',
    filters: [{ name: 'courseStatuses', filterBy: ['Zaplanuj'], hide: true }, { name: 'clientBdoTypes', hide: true, disabled: true }, { name: 'bdoCardStatuses', hide: true, disabled: true }]
  },
  {
    value: 'coursesWithoutCards',
    tableName: 'coursesWithoutCards',
    text: 'Kursy bez karty',
    filters: [{ name: 'courseStatuses', filterBy: [], hide: true }, { name: 'clientBdoTypes', hide: true, disabled: true }, { name: 'bdoCardStatuses', hide: true, disabled: true }, { name: 'withAssignedBdoCard', filterBy: false, hide: true }]
  },
  {
    value: 'bdoCardObsolete',
    tableName: 'coursesWithoutCards',
    text: 'Ogony',
    filters: [{ name: 'courseStatuses', filterBy: ['Wykonany'], hide: true }, { name: 'clientBdoTypes', hide: true, disabled: true }, { name: 'bdoCardStatuses', hide: true, disabled: true }, { name: 'withAssignedBdoCard', filterBy: false, hide: true }]
  },
  { value: 'routes', tableName: 'routes', text: 'Zaplanowane trasy', filters: [{ name: 'withAssignedBdoCard', filterBy: false, hide: true }] },
  {
    value: 'routesCompleted',
    tableName: 'routesCompleted',
    text: 'Wykonane trasy',
    filters: [{ name: 'withAssignedBdoCard', filterBy: false, hide: true }]
  },
])

export default {
  components: {
    Header,
    Tabs,
    Table,
    SinglePreviewRoute,
    SingleViewOrder,
    FiltersBar,
    Sidebar,
    MultipleItemsActions,
    TableStatisticsBar,
    TableConfirmationBar
  },
  mixins: [defaultTableEventsMixin, webSocketMixin],
  data: () => ({
    tableName: 'courses',
    currentTab: 'courses',
    chosenItemId: null,
    firstInit: true,
    configured: {
      courses: false,
      coursesWithoutCards: false,
      routes: false,
      routesCompleted: false,
    },
    actions,
    tabs,
  }),
  channels: {
    CourseIndexChannel: {
      received(data) {
        this.captureChanges(data)
        this.getCounters()
      }
    },
    CoursePagesChannel: {
      received(data) {
        this.captureChanges(data)
      }
    },
    RouteIndexChannel: {
      received(data) {
        this.captureChanges(data)
      }
    },
    OrderShowChannel: {
      received(data) {
        this.captureSingleChange(data)
      }
    }
  },
  computed: {
    ...mapState({
      course: state => state.course.entity,
      order: state => state.order.entity,
      route: state => state.route.entity,
      department: state => state.core.department,
      swapOrder: state => state.order.swapOrder,
      isProcessing: function (state) {
        return state[this.tableName].isProcessing
      },
    }),
    ...mapGetters({
      getTableParameters: 'tables/getTableParameters',
      getFilterValue: 'tables/getFilterValue',
    }),
    isMultiselectActive() {
      return this.$store.state[this.tableName]?.isMultiselectActive
    },
    selectedItems() {
      return this.$store.state[this.tableName]?.selectedItems || []
    },
    multiselectSubject() {
      return this.$store.state[this.tableName].multiselectSubject
    },
    tableDateRange() {
      return this.$store.state[this.tableName].dateRange
    },
    items() {
      return this.$store.state[this.tableName]?.items
    },
    multipleActionButtons() {
      const exportAction = {
        label: 'Exportuj do csv',
        subject: 'exportCoursesToCsv',
        shortkey: exportShortkeys,
        confirmActions: [
          { ...actions.exportAllCoursesToCsv, action: `${this.tableName}/exportAllCoursesSpreadsheet` },
          {
            ...actions.exportMultipleCoursesToCsv,
            action: `${this.tableName}/exportCoursesSpreadsheet`,
            tableName: this.tableName,
            disabled: !this.selectedItems.length
          }
        ]
      }
      const changeDateAction = {
        label: 'Zmień daty realizacji',
        subject: 'changeCoursesDueDates',
        shortkey: multiselectShortkeys,
        confirmActions: [
          actions.editAllCoursesDueDates,
          {
            ...actions.editMultipleCoursesDueDates,
            tableName: this.tableName,
            disabled: !this.selectedItems.length
          }
        ]
      }
      switch (this.tableName) {
        case 'courses':
          return [
            changeDateAction,
            exportAction
          ]
        case 'coursesWithoutCards':
        case 'routesCompleted':
          return [
            exportAction
          ]
        default:
          return []
      }
    },
    confirmationBarActions () {
      return this.multipleActionButtons.find(action => action.subject === this.multiselectSubject)?.confirmActions || []
    },
    detailsKey() {
      return this.chosenItem?.id
    },
    chosenItem() {
      return this.items?.find(item => item.id === this.chosenItemId)
    },
    componentTableDetails() {
      const component = {
        courses: 'SingleViewOrder',
        routes: 'SinglePreviewRoute',
        routesCompleted: 'SinglePreviewRoute'
      }
      return component?.[this.tableName]
    }
  },
  watch: {
    tableDateRange(newDate) {
      const [channel] = this.activeChannels
      this.unsubscribeSocket(channel)
      const params = this.tableName === 'coursesWithoutCards' ? undefined : { dueDate: newDate[0] }
      this.subscribeSocket(channel, params)
    },
    isMultiselectActive() {
      this.$refs.table.resizeTable()
    },
  },
  mounted() {
    this.subscribeSocket('CourseIndexChannel', {
      dueDate: this.tableDateRange?.[0]
    })
    this.unsubscribeAction = this.$store.subscribeAction({
      after: action => {
        if (['courses/getItems', 'coursesWithoutCards/getItems'].includes(action.type)) {
          this.getCounters()
        }
      }
    })
  },
  beforeDestroy() {
    this.unsubscribeSocket('CoursePagesChannel')
    this.unsubscribeSocket('CourseIndexChannel')
    this.unsubscribeSocket('RouteIndexChannel')
    this.unsubscribeAction()
  },
  methods: {
    ...mapActions({
      toggleMultiselectStatus: function(dispatch, status) {
        return dispatch(`${this.tableName}/toggleMultiselectStatus`, status)
      },
      getSingleRoute: 'route/getSingleRoute',
      resetRoutePlanning: 'route/resetRoutePlanning',
      getSingleOrder: 'order/getSingleOrder',
      clearSingleOrder: 'order/clearSingleOrder',
      setGlobalLoader: 'core/setLoading',
      setTableFilters: 'tables/setTableFilters',
      setSidebar: 'layout/setSidebar',
      closeSidebar: 'layout/closeSidebar',
      clearTableItems: 'courses/clearTableItems',
      getCounters: function(dispatch) {
        return dispatch(`${this.tableName}/getCounters`)
      },
    }),
    getLabelWithShortkey,
    setTableConfiguredStatus(tableName) {
      this.configured[tableName] = true
    },
    async goTo(rawTab) {
      const tab = cloneDeep(rawTab)
      this.clearTableItems()
      if (this.currentTab === 'courses' || this.firstInit) {
        const filterValue = this.getFilterValue(this.tableName, 'courseStatuses')
        const originalTabIndex = this.tabs.findIndex(tab => tab.value === 'courses')
        const courseStatusFilterIndex = this.tabs[originalTabIndex].filters.findIndex(filter => filter.name === 'courseStatuses')
        this.tabs[originalTabIndex].filters[courseStatusFilterIndex].filterBy = filterValue
        if (this.firstInit && tab.value === 'courses') {
          this.firstInit = false
          tab.filters[courseStatusFilterIndex].filterBy = filterValue
        }
      }
      const { tableName, value, filters } = tab
      this.tableName = tableName
      this.chosenItemId = null
      this.closeSidebar()
      this.currentTab = value
      // TODO if we are about to cache courses, those lines should be moved somewhere else
      if (!this.configured[this.tableName]) {
        await this.$nextTick() // wait for table to be configured
      }
      if (filters) {
        this.setTableFilters({ filters, tableName })
      }
      if (tableName === 'courses') {
        // TODO handle ws changes in coursesToBePlanned tab
        this.subscribeSocket('CourseIndexChannel', {
          dueDate: this.tableDateRange[0]
        })
        this.unsubscribeSocket('RouteIndexChannel')
        this.unsubscribeSocket('CoursePagesChannel')
      } else if (tableName === 'coursesWithoutCards') {
        this.subscribeSocket('CoursePagesChannel')
        this.unsubscribeSocket('RouteIndexChannel')
        this.unsubscribeSocket('CourseIndexChannel')
      } else {
        this.subscribeSocket('RouteIndexChannel', {
          dueDate: this.tableDateRange[0]
        })
        this.unsubscribeSocket('CoursePagesChannel')
        this.unsubscribeSocket('CourseIndexChannel')
      }
    },
    selectTableRow(data) {
      this.chosenItemId = data.id
      if (this.tableName === 'routes' && this.route.id !== data.id) {
        this.getSingleRoute(data.id)
      }
    },
    openTableDetails(data) {
      if (this.tableName !== 'routes') {
        const id = data.orderId
        const emptyOrderId = -1
        if (id !== emptyOrderId) {
          this.$router.push({
            name: 'singleOrder',
            params: { id, originTable: 'courses' },
            query: { nonSwapView: data.courseType === 'Zabranie' }// Don't display swap view for pickup courses only
          })
          this.subscribeSocket('OrderShowChannel', { orderId: id })
        } else {
          this.setSidebar({ size: 2 })
        }
      }
    }
  }
}
</script>
