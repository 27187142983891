import Base from './base'
import api from '../api/v1'
import store from './index'
import { downloadFile, getTableDaterangeCriteria, checkDateRange } from '../utils'
import get from 'lodash/get'
import { decamelize } from 'humps'
import debounce from 'lodash/debounce'
import apiMessages from '../const/apiMessages'

const orderMatchesFiltersCriteria = (item, filters) => {
  return filters.every(filter => {
    const { filterBy, propPath, checkCourseType, name } = filter
    const filterValue = filterBy.value ?? filterBy
    if (checkCourseType) {
      const checkCourseTypeValue = value => {
        switch (value) {
          case 'Wymiana':
            return item.swap
          case 'Transport':
            return item.otherCourseType
          case 'Zabranie':
            return item.pickupCourseType
          case 'Podstawienie':
            return item.leaveCourseType
        }
      }
      if (Array.isArray(filterValue)) {
        filterValue.some(singleFilter => checkCourseTypeValue(singleFilter.value || singleFilter))
      } else {
        checkCourseTypeValue(filterValue)
      }
    }
    if (name === 'clientIds') {
      const { clientId, middlemanId } = item
      return filterValue.some(singleFilter => [clientId, middlemanId].includes(singleFilter.value ?? singleFilter))
    }
    return Array.isArray(filterValue)
      ? filterValue.some(singleFilter => get(item, propPath) === (singleFilter.value ?? singleFilter))
      : get(item, propPath) === filterValue
  })
}

const orderMatchesDateRangeCriteria = (item, daterange, tableName) => {
  const configCriteria = getTableDaterangeCriteria(tableName)

  const criteriaConfig = {
    Podstawienie: 'leaveCourseDueDate',
    Zabranie: 'pickupCourseDueDate',
    Transport: 'otherCourseDueDate'
  }
  const courseTypeFilterCriteria = store.getters['tables/getFilterValue'](tableName, 'courseTypes')?.map(
    type => criteriaConfig[type.value]
  )

  const daterangeCriteria =
    courseTypeFilterCriteria.length && store.state.tables[tableName].filtersEnabled
      ? courseTypeFilterCriteria
      : configCriteria

  if (!daterangeCriteria.length) return true

  return daterangeCriteria.some(criteria => checkDateRange(get(item, criteria), daterange))
}

const orders = (tableName) => {
  const base = new Base(tableName, orderMatchesFiltersCriteria, orderMatchesDateRangeCriteria)

  const state = {
    newOrderProducts: [],
  }
  const mutations = {
    SET_PRODUCTS(state, products) {
      state.newOrderProducts = products
    },
  }

  const actions = {
    exportOrdersSpreadsheet ({ state, commit, rootState, rootGetters }) {
      commit('SET_PROCESSING', true)
      const orderIds = state.selectedItems

      const orderHeaders = rootState.tables[tableName].parameters
        .filter(header => header.show && !['select', 'actions'].includes(header.name))
        .map(header => decamelize(header.name))

      const { order, filters, departmentId } = rootGetters['tables/getTableParameters'](tableName).params

      api.exportOrdersSpreadsheet({
        departmentId,
        orderHeaders,
        orderIds,
        order,
        filters
      }).then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'text/csv' }))
        downloadFile(url, `Zlecenia ${state.dateRange}`)
        commit('UNSELECT_ALL_ITEMS')
      })
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    editItem: debounce(function ({ commit, dispatch }, data) {
      commit('SET_PROCESSING', true)
      return new Promise((resolve) => {
        api.updateItem(data)
          .then((resp) => {
            const response = resp.data?.success || resp.data?.errors
            const message = tableName === 'orders' && response ? response : apiMessages(tableName, 'update')
            const messageType = resp.data?.errors ? 'error' : 'success'
            resolve(resp.data)
            dispatch('snackbar/showSnackbar', {
              message,
              type: messageType
            }, { root: true })
          })
          .finally(() => {
            commit('SET_PROCESSING', false)
          })
      })
    }, 300, { leading: true }),
    sendPaymentLinkByMail({ commit }, data) {
      commit('SET_PROCESSING', true)
      return api.sendPaymentLinkByMail(data)
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    sendPaymentLinkBySMS({ commit }, data) {
      commit('SET_PROCESSING', true)
      return api.sendPaymentLinkBySMS(data)
        .finally(() => {
          commit('SET_PROCESSING', false)
        })
    },
    getCounters ({ commit, rootGetters }) {
      const params = rootGetters['tables/getTableParameters'](tableName).params
      api.getOrdersCounters(params)
        .then((resp) => {
          commit('SET_COUNTERS', resp.data)
        })
    },
  }

  base.state = {
    ...base.state,
    ...state
  }
  base.mutations = {
    ...base.mutations,
    ...mutations
  }
  base.actions = {
    ...base.actions,
    ...actions
  }

  return base
}

export default orders
